@media (max-width: 1023px) {
    #filter {
        display: none;
    }
    .hidden-items {
        display: none;
    }
}

.smallThumbnailCard {
    height: 20px;
}